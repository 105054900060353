import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "krapukhin_four_goal_contributions_in_debut" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Stanislav Krapukhin, a Russian forward from Riga Football Club, scored two goals and had two assists yesterday in his first official game in the Latvian Virslīga. Catenaccio Sports Agency congratulates Stanislav and wishes him all the best in the next games.</p>
        </div>
      </div>
    )
  }
}